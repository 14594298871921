var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"landing__benefits landing__section landing__section--benefits"},[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"landing__section__title text-center"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$tc('common.benefit', 2)))+" ")]),_c('b-card',{staticClass:"tabs__container",attrs:{"no-body":""}},[_c('b-tabs',{staticClass:"tabs__container__content",attrs:{"pills":"","card":"","vertical":""}},[_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab1'),"active":""}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab1Title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab1Text'))+".")]),_c('router-link',{attrs:{"to":("/property/" + (_vm.asset && _vm.asset.id)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"button button--primary button--large",attrs:{"alt":_vm._f("capitalize")(_vm.$t('landing.benefits.exploreProperties'))},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('landing.benefits.exploreProperties'))+" ")])]}}])})],1),_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab2')}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab2Title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab2Text'))+".")]),_c('router-link',{attrs:{"to":("/property/" + (_vm.asset && _vm.asset.id)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"button button--primary button--large",attrs:{"alt":_vm._f("capitalize")(_vm.$t('landing.benefits.exploreProperties'))},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('landing.benefits.exploreProperties'))+" ")])]}}])})],1),_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab3')}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab3Title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab3Text'))+".")]),_c('router-link',{attrs:{"to":("/property/" + (_vm.asset && _vm.asset.id)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"button button--primary button--large",attrs:{"alt":_vm._f("capitalize")(_vm.$t('landing.benefits.exploreProperties'))},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('landing.benefits.exploreProperties'))+" ")])]}}])})],1),_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab4')}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab4Title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab4Text1'))+".")]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab4Text2'))+".")]),_c('router-link',{attrs:{"to":("/property/" + (_vm.asset && _vm.asset.id)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"button button--primary button--large",attrs:{"alt":_vm._f("capitalize")(_vm.$t('landing.benefits.exploreProperties'))},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('landing.benefits.exploreProperties'))+" ")])]}}])})],1),_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab5')}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab5Title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab5Text')))]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col--12 col-md--6"},[_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint1'))),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint2'))),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint3'))),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint4'))+" ")]),_c('div',{staticClass:"col--12 col-md--6"},[_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint5'))),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint6'))),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('landing.benefits.tab5TextPoint7'))+" ")])]),_c('router-link',{attrs:{"to":("/property/" + (_vm.asset && _vm.asset.id)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"button button--primary button--large",attrs:{"alt":_vm._f("capitalize")(_vm.$t('landing.benefits.exploreProperties'))},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('landing.benefits.exploreProperties'))+" ")])]}}])})],1),_c('b-tab',{staticClass:"tabs__container__content--item shadow--small",attrs:{"title":_vm.$t('landing.benefits.tab6')}},[_c('h6',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab6Title')))]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab6Satisfied')))]),_vm._v(" "+_vm._s(_vm.$t('landing.benefits.tab6Text1'))+".")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('landing.benefits.tab6SmartPurchases')))]),_vm._v(" "+_vm._s(_vm.$t('landing.benefits.tab6Text2'))+".")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }